import { useContext } from 'react';
import { I18nContext } from 'common/useT';
import Widget from 'components/Dashboard/Widget';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import { GetFleetServiceStatusesDoc, VehicleServiceReminderStatus } from 'generated/graphql';
import { DashboardWidget } from 'types/widgets';
import { useQ } from 'utils/apolloClient';
import DonutSegmentChart, { DonutSegmentColor } from 'components/Charts/DonutSegmentChart';
import { theme } from 'twin.macro';
import NoDataResponse, { ResponseType } from 'atoms/NoDataResponse';

const colorMap: Record<VehicleServiceReminderStatus, DonutSegmentColor> = {
  [VehicleServiceReminderStatus.Off]: DonutSegmentColor.DarkGreen,
  [VehicleServiceReminderStatus.Due]: DonutSegmentColor.Yellow,
  [VehicleServiceReminderStatus.Overdue]: DonutSegmentColor.DarkRed,
};

const ServiceStatusWidget = () => {
  const i18nContext = useContext(I18nContext);

  const currentFleetId = useCurrentFleetId();
  const { loading, error, data } = useQ(GetFleetServiceStatusesDoc, {
    variables: {
      fleetId: currentFleetId,
    },
  });

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      enums: { dashboardWidgetDescriptionMap, serviceReminderStatusDescriptionMap },
    },
    tSafe,
  } = i18nContext;

  const pieData =
    data?.serviceStatuses?.map(({ serviceStatus, count }) => {
      return {
        text: serviceReminderStatusDescriptionMap[serviceStatus],
        value: count ?? 0,
        color: colorMap[serviceStatus as VehicleServiceReminderStatus],
        link: `${currentFleetId}/list-view?serviceStatus=${serviceStatus}`,
      };
    }) ?? [];

  return (
    <Widget title={dashboardWidgetDescriptionMap[DashboardWidget.serviceStatusWidget]} loading={loading} error={error}>
      {data?.serviceStatuses.length ? (
        <DonutSegmentChart
          data={pieData}
          totalCount={data?.serviceStatuses?.reduce((acc, curr) => acc + curr.count, 0) || 0}
          valueType={tSafe('common.domain.vehicle.fields.vehicleService', {
            defaultValue: 'vehicle service',
          })}
          innerValueSize={theme`fontSize.5xl`}
          mobileMargin={{ x: 20, y: 40 }}
          desktopMargin={{ x: 30, y: 50 }}
          legendColumnCount={2}
          showMobileLegend
        />
      ) : (
        <NoDataResponse reason={ResponseType.NoFleetData} />
      )}
    </Widget>
  );
};

export default ServiceStatusWidget;
