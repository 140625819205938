import { useMutation } from '@apollo/client';

import useWidgetMap from 'components/Dashboard/widgetMap';
import useSettings from 'components/Settings/useSettings';
import { SetDashboardLayoutDoc } from 'generated/graphql';
import { SerializedGridLayout, WidgetArea } from 'types/gridLayout';
import { DashboardWidgetSettings } from 'types/settings';
import { DashboardWidget } from 'types/widgets';

const widgetSettingsToSerialisedLayout = (widgetSettings: DashboardWidgetSettings) =>
  Object.entries(widgetSettings).reduce((acc, [widgetName, settings]) => {
    acc[widgetName] = settings.layout;
    return acc;
  }, {} as SerializedGridLayout);

export const useDeleteWidget = () => {
  const { dashboardWidgetSettings } = useSettings();
  const [setLayout] = useMutation(SetDashboardLayoutDoc);

  const deleteWidget = (name: string) => {
    const serializedLayout = widgetSettingsToSerialisedLayout(dashboardWidgetSettings);

    delete serializedLayout[name];

    setLayout({ variables: { dashboardLayout: serializedLayout } });
  };

  return deleteWidget;
};

export const useAddWidget = () => {
  const { dashboardWidgetSettings } = useSettings();
  const [setLayout] = useMutation(SetDashboardLayoutDoc);

  const deleteWidget = (name: string) => {
    const serializedLayout = Object.entries(dashboardWidgetSettings).reduce((acc, [widgetName, settings]) => {
      acc[widgetName] = settings.layout;
      return acc;
    }, {} as SerializedGridLayout);

    const area: WidgetArea = { x: 0, y: 0, h: useWidgetMap[name as DashboardWidget].minH ?? 1, w: 1 };

    serializedLayout[name] = { sm: area, lg: area, md: area };

    setLayout({ variables: { dashboardLayout: serializedLayout } });
  };

  return deleteWidget;
};
