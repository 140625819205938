import { Table } from '@tanstack/react-table';
import classNames from 'classnames';
import { formatDuration, intervalToDuration } from 'date-fns';
import React, {
  ComponentProps,
  createContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  VoidFunctionComponent,
} from 'react';
import { Helmet } from 'react-helmet-async';
import { theme } from 'twin.macro';

import { DeviceConnectionStatus } from 'generated/graphql';
import { StringKeys } from 'types';

export const usePrevious = <T,>(value: T): T => {
  const ref = useRef<T>(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

// eslint-disable-next-line react-hooks/exhaustive-deps
export const useUniqueKey = (deps: unknown[]) => useMemo(Math.random, deps);

export const useConditionalKey = (condition: boolean) => {
  const [key, setKey] = useState(Math.random());

  useEffect(() => {
    if (condition) setKey(Math.random());
  }, [condition]);

  return key;
};

export const arrayToMap = <T,>(arr: string[], value: T) =>
  arr.reduce((acc, key) => {
    acc[key] = value;
    return acc;
  }, {} as Record<string, T>);

export const useIsUnmounted = () => {
  const [isUnmounted, setIsUnmounted] = useState(false);

  useEffect(() => () => setIsUnmounted(true), []);

  return isUnmounted;
};

export const withWrapper =
  <T,>(Component: React.ComponentType<T>, Wrapper: React.ComponentType) =>
  (props: T) =>
    (
      <Wrapper>
        <Component children {...props} />
      </Wrapper>
    );

export const entries = <T extends object>(object: T) => Object.entries(object) as [keyof T, T[keyof T]][];
export const values = <T extends object>(object: T) => Object.values(object) as T[keyof T][];
export const keys = <T extends object>(object: T) => Object.keys(object) as (keyof T)[];

export const lToGal = (value: number, digits: number = 0) => +(value * 0.219969).toFixed(digits);
export const kmToMi = (value: number, digits: number = 0) => +(value * 0.62137).toFixed(digits);
export const miToKm = (value: number, digits: number = 0) => +(value * 1.60934).toFixed(digits);

// returns a wrapper around the passed component that sets default props, but still allows to override them
export const defaultPropsWrapper =
  <P extends object>(Component: React.VFC<P>, props = {} as P) =>
  (overrideProps?: Partial<P>) =>
    <Component {...props} {...overrideProps} />;

export const omit = <T extends object, K extends keyof T>(obj: T, ...keys: K[]) => {
  const { ...copy } = obj;

  keys.forEach((key) => delete copy[key]);

  return copy as Omit<T, typeof keys[number]>;
};

export const createTableContext = <D extends Record<string, unknown>>() =>
  createContext<Table<D> | undefined>(undefined);

export const cast = <T,>(x: T) => x;

export const capitalize = (value: string) => value?.charAt(0).toUpperCase() + value.slice(1);

export const getProgressColor = (value: number) => {
  const colourBands = [
    { floor: 80, colour: theme`colors.success` },
    { floor: 60, colour: theme`colors.yellow` },
    { floor: 40, colour: theme`colors.amber` },
    { floor: 20, colour: theme`colors.error` },
  ];

  return colourBands.find((colourBand) => value >= colourBand.floor)?.colour ?? theme`colors.black`;
};

export const cx = classNames;

export const arrayDedupeOnKey = <T extends object>(array: T[], key: StringKeys<T>) => {
  const map = array.reduce((acc, curr) => {
    const mapKey = curr[key] as unknown as string;
    acc[mapKey] ??= curr;

    return acc;
  }, {} as Record<string, T>);

  return values(map);
};

export const withTitle =
  <T extends VoidFunctionComponent<any>>(Component: T, title: string) =>
  (props: ComponentProps<T>) =>
    (
      <>
        <Helmet title={title} defer={false} />

        <Component {...props} />
      </>
    );

export const isDefined = <T,>(val: T | undefined | null): val is T => val !== undefined && val !== null;

export const getDeviceConnectionStatus = (
  connected: boolean | null | undefined,
  firstConnected: string | null | undefined,
  reporting: boolean | null | undefined,
) => {
  // TODO remove this and use STAG connection_status field
  if (connected) {
    if (!reporting) return DeviceConnectionStatus.NotReporting;
    return DeviceConnectionStatus.Connected;
  }
  if (!firstConnected) return DeviceConnectionStatus.NeverConnected;
  return DeviceConnectionStatus.Disconnected;
};

const formatDistanceLocale = { xSeconds: '{{count}} sec', xMinutes: '{{count}} min', xHours: '{{count}} hr' };
const shortEnLocale = {
  formatDistance: (token: keyof typeof formatDistanceLocale, count: string) =>
    formatDistanceLocale[token].replace('{{count}}', count),
};
export const formatTime = (duration: number) =>
  formatDuration(intervalToDuration({ start: 0, end: duration! * 1000 }), {
    format: ['hours', 'minutes', 'seconds'],
    delimiter: ', ',
    locale: shortEnLocale,
  });

export const shortCurrencyFormat = (value: number) => {
  return value.toLocaleString('en-GB', {
    maximumFractionDigits: 2,
    notation: 'compact',
    compactDisplay: 'short',
    style: 'currency',
    currency: 'GBP',
  });
};

export const reverseRecord = <T extends PropertyKey, U extends PropertyKey>(input: Record<T, U>) =>
  Object.fromEntries(Object.entries(input).map(([key, value]) => [value, key])) as Record<U, T>;

export const groupByKeyToMap = <T extends { [key: string | number | symbol]: any }, K extends StringKeys<T>>(
  array: T[],
  key: K,
) => {
  const map = {} as Record<T[K], Array<T>>;

  array.forEach((item) => {
    const groupKey = item[key];

    map[groupKey] ??= [];

    map[groupKey].push(item);
  });

  return map;
};
