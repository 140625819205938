import { ComponentType } from 'react';
import { Layout } from 'react-grid-layout';

import * as BatteryEventCountWidget from 'components/Dashboard/BatteryEventCountWidget';
import * as DashboardLightEventCountWidget from 'components/Dashboard/DashboardLightEventCountWidget';
import * as DeviceStatusWidget from 'components/Dashboard/DeviceStatusWidget';
import * as SericeStatusWidget from 'components/Dashboard/ServiceStatusWidget';
import * as DriverStatsWidget from 'components/Dashboard/FleetDriverStatsWidget';
import * as DtcEventCountWidget from 'components/Dashboard/DtcEventCountWidget';
import * as IdleTimeLeaderboardWidget from 'components/Dashboard/IdleTimeLeaderboardWidget';
import * as LeaderboardWidget from 'components/Dashboard/LeaderboardWidget';
import * as UtilisationWidget from 'components/Dashboard/UtilisationWidget';
import { DashboardWidget } from 'types/widgets';

const widgetMap: Record<DashboardWidget, Partial<Layout> & { component: ComponentType }> = {
  fleetDriverStatsWidget: {
    component: DriverStatsWidget.default,
    isResizable: false,
  },
  leaderboardWidget: {
    component: LeaderboardWidget.default,
  },
  idleTimeLeaderboardWidget: {
    component: IdleTimeLeaderboardWidget.default,
  },
  batteryEventCountWidget: {
    component: BatteryEventCountWidget.default,
  },
  dashboardLightEventCountWidget: {
    component: DashboardLightEventCountWidget.default,
  },
  deviceStatusWidget: {
    component: DeviceStatusWidget.default,
    maxW: 1,
    maxH: 6,
    minH: 5,
  },
  serviceStatusWidget: {
    component: SericeStatusWidget.default,
    maxW: 1,
    maxH: 6,
    minH: 5,
  },
  dtcEventCountWidget: {
    component: DtcEventCountWidget.default,
  },
  utilisationWidget: {
    component: UtilisationWidget.default,
  },
};

export default widgetMap;
