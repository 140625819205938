import { Label, Pie, PieChart, ResponsiveContainer, Sector, Text, Tooltip, TooltipProps } from 'recharts';
import { theme } from 'twin.macro';
import { useContext, useState } from 'react';
import { RADIAN } from 'common/constants';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { I18nContext } from 'common/useT';
import { useHistory } from 'react-router-dom';
import { EntityAssociation } from 'generated/graphql';
import { cx } from 'utils';

export enum DonutSegmentColor {
  DarkGreen = 'DarkGreen',
  LightGreen = 'LightGreen',
  Yellow = 'Yellow',
  Orange = 'Orange',
  LightRed = 'LightRed',
  DarkRed = 'DarkRed',
}

const segmentColorMap: Record<DonutSegmentColor, string> = {
  [DonutSegmentColor.DarkGreen]: '#3acc83',
  [DonutSegmentColor.LightGreen]: '#99e17a',
  [DonutSegmentColor.Yellow]: '#ffe47c',
  [DonutSegmentColor.Orange]: '#ffa773',
  [DonutSegmentColor.LightRed]: '#ff7a7a',
  [DonutSegmentColor.DarkRed]: '#ff5d5d',
};

interface DonutSegment {
  text: string;
  color: DonutSegmentColor;
  value: number;
  link?: string;
}

interface DonutChartProps {
  data: DonutSegment[];
  totalCount: number;
  valueType: string;
  innerValue?: string | number;
  innerLabel?: string;
  innerValueSize?: string;
  innerValueClassName?: string;
  showMobileLegend?: boolean;
  legendColumnCount?: number;
  mobileMargin?: { x: number; y: number };
  desktopMargin?: { x: number; y: number };
}

interface ActiveShapeProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  startAngle: number;
  endAngle: number;
  fill: string;
  name: string;
  percent: number;
}

const DonutSegmentChart = ({
  data,
  totalCount,
  valueType,
  innerLabel,
  innerValue,
  innerValueSize,
  innerValueClassName,
  showMobileLegend,
  legendColumnCount = 3,
  mobileMargin = { x: 50, y: 50 },
  desktopMargin = { x: 50, y: 50 },
}: DonutChartProps) => {
  const i18nContext = useContext(I18nContext);
  const [activeIndex, setActiveIndex] = useState(-1);
  const history = useHistory();
  const useMobileView = window.innerWidth < 768;

  if (!i18nContext) return null;

  const {
    commonTranslations: {
      errors: { no_data_fleet_text },
    },
  } = i18nContext;

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <div className="bg-white rounded-4 border-px border-gray-300 p-1">
            <span>
              <p>{`${payload?.[0]?.value} ${valueType} ${payload?.[0]?.name}`}</p>
            </span>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderLabel = ({ cx, cy, midAngle, outerRadius, name, percent }: ActiveShapeProps) => {
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 15) * cos;
    const my = cy + (outerRadius + 15) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1);
    const ey = my + (sin >= 0 ? 4 : -8);
    const textAnchor = cos >= 0 ? 'start' : 'end';

    return (
      <g>
        {(!useMobileView || !showMobileLegend) && (
          <Text
            x={ex + (cos >= 0 ? 1 : -1)}
            y={ey}
            textAnchor={textAnchor}
            fill="#555"
            className="text-md"
            width={60}
          >{`${name}`}</Text>
        )}
        <text
          x={ex + (cos >= 0 ? 1 : -1)}
          y={ey + 16}
          textAnchor={textAnchor}
          className="text-md"
          color="black"
          fill="#777"
        >{`${(percent * 100).toFixed(0)}%`}</text>
      </g>
    );
  };

  const renderActiveShape = ({
    cx: x,
    cy: y,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
  }: ActiveShapeProps) => {
    return (
      <g>
        <Sector
          onClick={() =>
            data[activeIndex].link &&
            history.push(data[activeIndex].link!, {
              associationState: EntityAssociation.AllVehicles,
              defaultList: true,
            })
          }
          cx={x}
          cy={y}
          innerRadius={innerRadius}
          outerRadius={outerRadius + 4}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
          className={cx(data[activeIndex].link && 'cursor-pointer')}
        />
      </g>
    );
  };

  const donutData = data.length
    ? data.map(({ text: name, value, color, link }) => {
        return {
          name,
          value,
          fill: segmentColorMap[color],
          link,
        };
      })
    : [{ name: '', value: 100, fill: '#ccc' }];

  return (
    <div className="flex flex-center items-stretch flex-col w-full h-full">
      <ResponsiveContainer width="100%" height="100%">
        <PieChart
          margin={
            useMobileView
              ? { top: mobileMargin?.y, bottom: mobileMargin?.y, left: mobileMargin?.x, right: mobileMargin?.x }
              : { top: desktopMargin?.y, bottom: desktopMargin?.y, left: desktopMargin?.x, right: desktopMargin?.x }
          }
        >
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            dataKey="value"
            data={donutData}
            innerRadius="50%"
            outerRadius="100%"
            startAngle={90}
            endAngle={-270}
            labelLine={false}
            label={renderLabel}
            isAnimationActive={false}
            onMouseEnter={(_, index) => setActiveIndex(index)}
            onMouseLeave={() => setActiveIndex(-1)}
          >
            {data.length ? (
              <>
                {innerLabel && <Label value={innerLabel} position="center" fontSize={theme`fontSize.md`} dy={-26} />}
                <Label
                  value={innerValue ?? totalCount}
                  position="center"
                  className={innerValueClassName}
                  fontSize={innerValueSize ?? theme`fontSize.6xl`}
                  dy={innerLabel ? 12 : 0}
                />
              </>
            ) : (
              <Label value={no_data_fleet_text} position="center" width={100} fontSize={theme`fontSize.sm`} />
            )}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>

      {showMobileLegend && useMobileView && (
        <div
          className="mt-auto grid p-1"
          style={{ gridTemplateColumns: `repeat(${legendColumnCount}, minmax(0, 1fr))` }}
        >
          {donutData.map(({ fill, name }, i) => (
            <div className="flex-center mr-2 p-0.5" onMouseOver={() => setActiveIndex(i)}>
              <div className="h-1.5 w-1.5 mr-0.5 rounded-full" style={{ backgroundColor: fill }}></div>
              <div>{name}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DonutSegmentChart;
