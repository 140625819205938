import { useContext } from 'react';

import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import { WidgetContext } from 'components/Dashboard/Widget';
import WidgetItem from 'components/Dashboard/Widget/WidgetItem';
import { cx } from 'utils';

export type TileIconMap<T extends string> = Record<T, IconName | { name: IconName; className?: string }>;

export interface Tile {
  name: string;
  description: string;
  value: number | string;
  icon: TileIconMap<string>[string];
  className?: string;
  onClick?: () => void;
  tooltip?: string;
}

interface WidgetTileProps extends Tile {
  selected?: boolean;
  onClick?: () => void;
  className?: string;
  valueClassName?: string;
  defaultIconClassName?: string;
  showZeroValues?: boolean;
  count?: boolean;
  borderWidth?: number;
  borderRadius?: number;
  borderColor?: string;
  paddingX?: number;
  paddingY?: number;
}

const WidgetTile = ({
  name,
  description,
  value,
  icon,
  selected,
  onClick,
  className,
  valueClassName,
  defaultIconClassName,
  showZeroValues,
  count,
  borderWidth = 1,
  borderRadius = 10,
  borderColor,
  paddingX,
  paddingY,
}: WidgetTileProps) => {
  const { isEditMode } = useContext(WidgetContext);

  if (!showZeroValues && !isEditMode && !value) return null;

  return (
    <WidgetItem name={name}>
      <div
        onClick={onClick}
        className={cx(
          'h-full flex items-center rounded-8 border-dark-gray/20 pl-1 space-x-2 xl:text-lg',
          className,
          onClick && 'cursor-pointer hover:bg-gray-100',
          selected && 'bg-gray-100',
          !value && 'text-dark-gray opacity-50',
        )}
        style={{
          borderWidth,
          borderRadius,
          borderColor,
          paddingTop: paddingY,
          paddingBottom: paddingY,
          paddingLeft: paddingX,
          paddingRight: paddingX,
        }}
      >
        <Icon
          name={typeof icon === 'string' ? icon : icon.name}
          className={`!w-4 py-1.5 flex-shrink-0 ${defaultIconClassName} ${
            typeof icon === 'string' ? '' : icon.className
          }`}
          aria-label={description}
        />

        <div className="flex flex-1 flex-col text-left leading-7 pr-[10px] space-y-1">
          <div className={cx('md:text-md break-all', !count && 'font-bold')}>{description}</div>

          {!count && <div className={`text-sm ${valueClassName ?? ''}`}>{value}</div>}
        </div>

        {count && (
          <div
            className={`flex-center flex-shrink-0 max-w-[50%] p-1.5 h-3/4 text-3xl border-l border-dark-gray/20 ${
              valueClassName ?? ''
            }`}
          >
            {value}
          </div>
        )}
      </div>
    </WidgetItem>
  );
};

export default WidgetTile;
