export enum DashboardWidget {
  leaderboardWidget = 'leaderboardWidget',
  batteryEventCountWidget = 'batteryEventCountWidget',
  dashboardLightEventCountWidget = 'dashboardLightEventCountWidget',
  deviceStatusWidget = 'deviceStatusWidget',
  serviceStatusWidget = 'serviceStatusWidget',
  fleetDriverStatsWidget = 'fleetDriverStatsWidget',
  idleTimeLeaderboardWidget = 'idleTimeLeaderboardWidget',
  dtcEventCountWidget = 'dtcEventCountWidget',
  utilisationWidget = 'utilisationWidget',
}

export enum VehicleDetailPageWidget {
  vehicleStatusWidget = 'vehicleStatusWidget',
  vehicleLastKnownLocationWidget = 'vehicleLastKnownLocationWidget',
  geofencesWidget = 'geofencesWidget',
  vehicleLevelsWidget = 'vehicleLevelsWidget',
  tyrePressureWidget = 'tyrePressureWidget',
  dashboardLightsWidget = 'dashboardLightsWidget',
  deviceInfoWidget = 'deviceInfoWidget',
}

export enum DriverDetailPageWidget {
  ecoScoreWidget = 'ecoScoreWidget',
  drivingStatsWidget = 'drivingStatsWidget',
  vehicleInfoWidget = 'vehicleInfoWidget',
  driverLastKnownLocationWidget = 'driverLastKnownLocationWidget',
}
