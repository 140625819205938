import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import IonIcon from 'atoms/IonIcon';

interface ServiceInfoItemProps {
  description?: string | number;
  className?: string;
  iconName?: IconName;
  showBorder?: boolean;
  showSeparator?: boolean;
}

export const ServiceInfoItem = ({
  description,
  className,
  iconName,
  showBorder,
  showSeparator,
}: ServiceInfoItemProps) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex items-center py-1">
          {iconName && (
            <div className="mx-1 min-w-4">
              <Icon name={iconName} />
            </div>
          )}

          <span className={`py-1 ${className}`}>{description}</span>

          {showBorder && <span className="ml-2 h-4 border-gray-400 border-r-px mr-1"></span>}

          {showSeparator && <IonIcon name="chevronForwardOutline" className="separator ml-1" role="separator" />}
        </div>
      </div>
    </>
  );
};
