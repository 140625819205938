import { Period } from 'generated/graphql';

export const defaultDashboardTimePeriod = Period.P30d;
export const europeCenterLatlng = { lat: 51.8, lng: 5.4 };
export const fakePhoneNumberPlaceholder = '+447700900815';
export const fakeEmailPlaceholder = 'email@example.com';
export const GOOGLE_MAPS_API_KEY = 'AIzaSyBUCyj6W-4gxzAxKMBjrcXzE7nyuO_Mz3A';
export const subdomain = window.location.host.split('.')[0];
export const thirtyTwoBitSignedIntLimit = 2147483647;
export const RADIAN = Math.PI / 180;
export const idleCostRatio = 0.0133;
