import { useContext, useState } from 'react';
import { useRecoilValue } from 'recoil';

import { TileIconMap } from 'atoms/WidgetTile';
import { tripStatisticConversionRatios } from 'common/uomConverters';
import { I18nContext } from 'common/useT';
import DateFilter from 'components/Dashboard/DateFilter';
import Widget, { WidgetContext, WidgetPageType } from 'components/Dashboard/Widget';
import { StatsTileGroup } from 'components/Driver/Detail/DrivingStats/TileGroup';
import { useDriverDetails } from 'components/Driver/Detail/hooks';
import useSettings from 'components/Settings/useSettings';
import { Period, TripStatisticType } from 'generated/graphql';

import { driverPageCollapsedWidgetsState } from '../state';
import { defaultDashboardTimePeriod } from 'common/constants';

const iconMap: TileIconMap<TripStatisticType> = {
  FUEL_CONSUMPTION: 'fuelconsumption',
  FUEL_COST: 'fuelCost',
  FUEL_EFFICIENCY: 'speedAverage',
  IDLE_TIME_PCT: 'speedIdle',
  IDLE_TIME_HRS: 'speedIdle',
  IDLE_COST: 'fuelCost',
  TOTAL_DISTANCE_DRIVEN: 'distancedriven',
  TOTAL_TIME_DRIVEN: 'timedriven',
  TRIP_COUNT: 'tripcount',
  ECO_SCORE: 'uncategorised',
};

const DrivingStats = () => {
  const i18nContext = useContext(I18nContext);
  const [period, setPeriod] = useState<Period>(defaultDashboardTimePeriod);
  const { data, loading, error } = useDriverDetails({ period });
  const { distanceInMiles, volumeInGallons, idleTimeAsPercentage } = useSettings();
  const driverPageCollapsedWidgets = useRecoilValue(driverPageCollapsedWidgetsState);
  const { widgetKey } = useContext(WidgetContext);

  const conversionRatioMap = tripStatisticConversionRatios({ distanceInMiles, volumeInGallons });

  if (!i18nContext) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        driverDetails: {
          fields: { statistics_text },
        },
      },
      enums: { tripStatisticTypeDescriptionMap },
    },
  } = i18nContext;

  const [
    {
      statistics: { tripStatistics },
    },
  ] = data ?? [{ statistics: { tripStatistics: [] } }];

  const tiles = tripStatistics
    .filter((x) => x.type !== TripStatisticType.EcoScore)
    .filter((x) =>
      idleTimeAsPercentage ? x.type !== TripStatisticType.IdleTimeHrs : x.type !== TripStatisticType.IdleTimePct,
    )
    .map(({ type, value, histogram }) => ({
      name: type,
      description: tripStatisticTypeDescriptionMap[type],
      value: Math.round(value * conversionRatioMap[type]),
      icon: iconMap[type],
      histogramData: histogram?.map(({ date, value }) => ({
        date,
        value: value * conversionRatioMap[type],
      })),
    }));

  return (
    <Widget title={statistics_text} collapsible loading={loading} error={error} page={WidgetPageType.Driver}>
      <div className="relative">
        {!driverPageCollapsedWidgets.includes(widgetKey) && (
          <div className="absolute -top-4 right-1 z-40">
            <DateFilter selectedPeriod={period} onSelect={(option) => setPeriod(option.value)} />
          </div>
        )}

        {tripStatistics?.length ? (
          <div className="ml-0.5 mb-0.5 px-1 pb-1 w-full h-25 bg-white">
            <StatsTileGroup tiles={tiles} showZeroValues />
          </div>
        ) : (
          <div className="ml-0.5 mb-0.5 p-1 bg-white">
            {tSafe('components.Driver.Detail.DrivingStats.driving-stats-no-stats', {
              defaultValue: 'No Driving Statistics data for selected time period',
            })}
          </div>
        )}
      </div>
    </Widget>
  );
};

export default DrivingStats;
