import { defaultPropsWrapper } from 'utils';

import SpeedIcon from './SpeedIcon';

export { ReactComponent as bell } from './bell.svg';
export { ReactComponent as bulb } from './bulb.svg';
export { ReactComponent as car } from './car.svg';
export { ReactComponent as connected } from './connected.svg';
export { ReactComponent as columns } from './columns.svg';
export { ReactComponent as dots } from './dots.svg';
export { ReactComponent as filter } from './filter.svg';
export { ReactComponent as fuelCost } from './fuelCost.svg';
export { ReactComponent as impact } from './impact.svg';
export { ReactComponent as keyboard } from './keyboard.svg';
export { ReactComponent as message } from './message.svg';
export { ReactComponent as fleet } from './fleet.svg';

export const speedIdle = defaultPropsWrapper(SpeedIcon, { level: 0 });
export const speedLow = defaultPropsWrapper(SpeedIcon, { level: 1 });
export const speedAverage = defaultPropsWrapper(SpeedIcon, { level: 2 });
export const speedHigh = defaultPropsWrapper(SpeedIcon, { level: 3 });
export const speedMax = defaultPropsWrapper(SpeedIcon, { level: 4 });
