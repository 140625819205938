import { Suspense, useContext } from 'react';

import Icon from 'atoms/Icon';
import { IconName } from 'atoms/Icon/icons';
import * as logos from 'atoms/Icon/logo';
import DriverVehicleSearch from 'atoms/DriverVehicleSearch';
import { subdomain } from 'common/constants';
import { I18nContext } from 'common/useT';
import { useCurrentFleetId } from 'components/FleetSelector/hooks';
import NavbarLink from 'components/Navbar/NavbarLink';
import Notifications from 'components/Navbar/Notifications';
import ProfilePopover from 'components/Navbar/ProfilePopover';
import useTenant from 'components/Tenant/useTenant';
import useRole from 'components/User/useRole';
import useUser from 'components/User/useUser';
import { cx } from 'utils';

const Navbar = () => {
  const currentFleetId = useCurrentFleetId({ optional: true });
  const { isViewer, isDriver, isSuperAdmin } = useRole();
  const { driverId, associations } = useUser();
  const tenant = useTenant();
  const i18nContext = useContext(I18nContext);

  if (!i18nContext || !tenant || !currentFleetId) return null;

  const {
    tSafe,
    commonTranslations: {
      domain: {
        driver: { drivers_text, driver_text },
        vehicle: { vehicles_text, vehicle_text },
        fleet: { fleets_text },
        user: { users_text },
        report: { reports_text },
      },
    },
  } = i18nContext;

  return (
    <div className="flex items-center bg-navbar" data-testid="navbar">
      <div className="grow flex items-center">
        <Icon
          name={logos[`${subdomain}Logo` as keyof typeof logos] ? (`${subdomain}Logo` as IconName) : 'fleetLogo'}
          className={cx('h-3 ml-4 mr-3 select-none text-black')}
        />

        <nav className="flex-1 hidden sm:flex children:flex font-bold uppercase">
          {isViewer && (
            <div className="flex-1">
              <NavbarLink to={`/${currentFleetId}`} exact>
                <>{tSafe('components.Navbar.link.dashboard', { defaultValue: 'Dashboard' })}</>
              </NavbarLink>

              <NavbarLink to={`/${currentFleetId}/live`}>
                <>{tSafe('components.Navbar.link.live-map', { defaultValue: 'Live' })}</>
              </NavbarLink>

              <NavbarLink to={`/${currentFleetId}/list-view`}>
                <>{tSafe('components.Navbar.link.list-view', { defaultValue: 'List view' })}</>
              </NavbarLink>

              <NavbarLink to={`/${currentFleetId}/fleets`}>
                <>{fleets_text}</>
              </NavbarLink>

              <NavbarLink to={`/${currentFleetId}/users`}>
                <>{users_text}</>
              </NavbarLink>

              {(tenant.reportingEnabled || isSuperAdmin) && (
                <NavbarLink to={`/${currentFleetId}/reporting`}>
                  <>{reports_text}</>
                </NavbarLink>
              )}
            </div>
          )}
        </nav>

        <nav className="hidden sm:flex ml-3 font-bold uppercase children:gap-5 gap-5">
          {isDriver && (
            <NavbarLink to={`/${currentFleetId}/drivers/${driverId}`}>
              <>{driver_text}</>
            </NavbarLink>
          )}

          {associations?.vehicle && (
            <NavbarLink to={`/${currentFleetId}/vehicles/${associations?.vehicle?.vehicle.id}`}>
              <>{vehicle_text}</>
            </NavbarLink>
          )}
        </nav>

        <div className="pr-4 flex h-7 ml-auto">
          <div className="hidden sm:flex">
            <Suspense fallback={<></>}>
              {isViewer && <DriverVehicleSearch />}

              <Notifications />
            </Suspense>
          </div>
          <ProfilePopover />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
