import { format, parseISO } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useContext } from 'react';

import { I18nContext } from 'common/useT';
import useSettings from 'components/Settings/useSettings';
import { VehicleServiceReminderStatus } from 'generated/graphql';
import { kmToMi } from 'utils';

import { useVehicleDetails } from '../hooks';
import { ServiceInfoItem } from './ServiceInfoItem';

const serviceReminderStatusTextColor: Record<VehicleServiceReminderStatus, string> = {
  Off: 'text-success',
  Due: 'text-amber',
  Overdue: 'text-error',
};

const ServiceInfo = () => {
  const i18nContext = useContext(I18nContext);
  const { loading, data: [vehicleDetails] = [] } = useVehicleDetails();
  const { distanceInMiles } = useSettings();

  if (loading || !i18nContext) return null;

  const {
    commonTranslations: {
      enums: { serviceReminderStatusDescriptionMap },
      general: { unavailable_text, no_data },
      domain: {
        vehicleDetails: {
          fields: { service_info_text },
        },
      },
    },
  } = i18nContext;

  const serviceInfo = vehicleDetails.serviceInfo;

  const serviceReminderStatus = serviceInfo?.vehicleServiceStatus;
  const serviceDate = serviceInfo?.vehicleServiceDate
    ? format(parseISO(serviceInfo?.vehicleServiceDate), 'P', { locale: enGB })
    : no_data;

  const odometerInKm = serviceInfo?.vehicleServiceDistance ? serviceInfo.vehicleServiceDistance / 1000 : undefined;
  const odometerConverted = distanceInMiles && odometerInKm ? kmToMi(odometerInKm) : odometerInKm;
  const odometerRounded = odometerConverted ? Math.round(odometerConverted) : undefined;
  const odometerUnit = distanceInMiles ? 'miles' : 'km';
  const serviceDistance = odometerRounded ? `${odometerRounded} ${odometerUnit}` : no_data;

  const serviceReminderStatusClassName = serviceReminderStatus
    ? serviceReminderStatusTextColor[serviceReminderStatus]
    : '';
  const serviceReminderStatusDescription = serviceReminderStatus
    ? serviceReminderStatusDescriptionMap[serviceReminderStatus]
    : unavailable_text;

  return (
    <div className="flex w-full my-0.5 p-1 text-xl bg-white">
      <div className="flex w-full">
        <ServiceInfoItem description={service_info_text} showSeparator={true} />

        <ServiceInfoItem
          className={serviceReminderStatusClassName}
          description={serviceReminderStatusDescription}
          iconName="overdueService"
          showBorder={true}
        />

        <ServiceInfoItem description={serviceDate} iconName="upcomingService" showBorder={true} />

        <ServiceInfoItem description={serviceDistance} iconName="checkService" />
      </div>
    </div>
  );
};

export default ServiceInfo;
